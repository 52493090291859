import React, { useState } from 'react';
import classNames from 'classnames';

import { PROJECT_THUMBNAIL_SLUGS } from 'lib/constants';
import { IProject } from 'lib/types/app';
import { TGithubRepos } from 'lib/hooks';
import Card from 'components/Card';
import ProjectThumbnail from './ProjectThumbnail';
import ProjectTechIcon from './ProjectTechIcon';
import { Modal } from 'components/Modal';
import ProjectGalleryModal from './ProjectGalleryModal';

interface ProjectItemProps {
  project: IProject;
  repos: TGithubRepos;
}

type ProjectThumbnailStylesType = {
  [key in PROJECT_THUMBNAIL_SLUGS]: string;
};

const ProjectThumbnailStyles: ProjectThumbnailStylesType = {
  MERN_ECOMMERCE: 'w-6 h-6',
  MEDCONNECT: 'w-10 h-8',
  MOVEINZ: 'w-8 h-8',
  GYM_BUDDY_WEB: 'w-12 h-12',
  SERVICE_PRO: 'w-8 h-8',
  CHATTY_APP: 'w-10 h-10',
  NEXT_CRYPTO_SAAS: 'w-10 h-10',
  NEXT_SAAS: 'w-8 h-8',
  NEXT_NFT_GALLERY: 'w-10 h-10',
  VUE_CRYPTO_SAAS: 'w-6 h-6',
  GO_VUE_AUTH: 'w-6 h-6',
  REACT_SNACKBAR_MESSAGES: 'w-6 h-6',
  WEB5_CHAT_APP: 'w-8 h-8',
  CHAT_COMPONENTS: 'w-8 h-8',
};

const ProjectItem = (props: ProjectItemProps) => {
  const { project, repos } = props;
  const thumbnailClassNames = ProjectThumbnailStyles[project.thumbnailSlug];
  const [showModal, setShowModal] = useState(false);

  const renderForks = () => {
    const repo = repos.get(project.name);
    if (!repo || !repo.forks_count || repo.forks_count === 0) return null;

    return (
      <div>
        <i
          className="mr-1 base text-tertiary-dark dark:text-tertiary-light fa fa-code-branch"
          aria-hidden="true"
          title="github forks"
        />
        <span className="base text-tertiary-dark dark:text-tertiary-light">
          {repo.forks_count}
        </span>
      </div>
    );
  };
  const renderStars = () => {
    const repo = repos.get(project.name);
    if (!repo || !repo.stargazers_count || repo.stargazers_count === 0)
      return null;

    return (
      <div>
        <i
          className="mr-1 text-yellow-400 base fa-regular fa-star"
          aria-hidden="true"
          title="github stars"
        />
        <span className="base text-tertiary-dark dark:text-tertiary-light">
          {Number(repo.stargazers_count).toLocaleString()}
        </span>
      </div>
    );
  };

  return (
    <>
      <Card>
        <div className="flex flex-col h-full">
          {/* Header */}
          <div className="flex items-center justify-between mb-4">
            <div className="flex flex-col justify-center overflow-hidden border-2 border-gray-200 rounded-full w-14 h-14 bg-secondary-light dark:border-secondary-dark">
              <ProjectThumbnail
                name={project.thumbnailSlug}
                className={classNames('mx-auto', thumbnailClassNames)}
              />
            </div>
            <div className="flex items-center gap-6">
              {renderStars()}
              {renderForks()}
            </div>
          </div>
          {/* Body */}
          <div className="flex-1 mb-6">
            <h3 className="mb-4 text-xl font-bold text-left md:text-2xl text-tertiary-dark dark:text-tertiary-light">
              {project.title}
            </h3>
            <p className="text-tertiary-dark dark:text-tertiary-light">
              {project.description}
            </p>
          </div>
          {/* Footer */}
          <div>
            <ul className="flex flex-row gap-2 mb-4 overflow-x-scroll text-2xl no-scrollbar">
              {project.tech.map((tech, idx) => (
                <li
                  key={idx}
                  className="flex flex-col justify-center min-w-[2.5rem] min-h-[2.5rem] overflow-hidden text-center border border-gray-200 rounded-full bg-secondary-light dark:bg-primary-dark dark:border-secondary-dark"
                >
                  <ProjectTechIcon name={tech} />
                </li>
              ))}
            </ul>
            <div className="flex items-center gap-4">
              {project.url && (
                <a
                  className="flex items-center justify-center gap-2 px-3 py-2 duration-200 border border-gray-200 rounded-3xl text-tertiary-dark dark:text-tertiary-light bg-secondary-light hover:bg-tertiary-light dark:bg-primary-dark dark:hover:bg-secondary-dark focus:outline-blue-300"
                  href={project.url}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="base fa fa-external-link" />
                  <span className="base">Demo</span>
                </a>
              )}
              {project.source && (
                <a
                  className="flex items-center justify-center gap-2 px-3 py-2 duration-200 border border-gray-200 rounded-3xl text-tertiary-dark dark:text-tertiary-light bg-secondary-light hover:bg-tertiary-light dark:bg-primary-dark dark:hover:bg-secondary-dark focus:outline-blue-300"
                  href={project.source}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <i className="base fab fa-github" />
                  <span className="base">Source</span>
                </a>
              )}
              {project.mobileScreenshotsSlug && (
                <button
                  className="flex items-center justify-center gap-2 px-3 py-2 duration-200 border border-gray-200 rounded-3xl text-tertiary-dark dark:text-tertiary-light bg-secondary-light hover:bg-tertiary-light dark:bg-primary-dark dark:hover:bg-secondary-dark focus:outline-blue-300"
                  onClick={() => setShowModal(true)}
                >
                  <i className="base fa fa-mobile-screen-button"></i>
                  <span className="text-base">Mobile</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </Card>
      <Modal
        isOpen={showModal}
        handleClose={() => setShowModal(false)}
        className="relative max-w-3xl max-h-[calc(100vh-2rem)] overflow-y-scroll no-scrollbar px-4 py-12 m-4 mx-auto border border-gray-200 rounded-lg shadow dark:border-secondary-dark bg-secondary-light dark:bg-tertiary-dark"
      >
        <ProjectGalleryModal project={project} />
      </Modal>
    </>
  );
};

export default ProjectItem;
