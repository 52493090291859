import React from 'react';

import Chakra from '../../../images/projects/tech/chakra.svg';
import GoogleMaps from '../../../images/projects/tech/google-maps.svg';
import Vue from '../../../images/projects/tech/vue.svg';
import Daisyui from '../../../images/projects/tech/daisyui.svg';
import Web5 from '../../../images/projects/tech/web5.svg';
import Shadcn from '../../../images/projects/tech/shadcn.svg';
import Radix from '../../../images/projects/tech/radix.svg';

type IconMapperType = {
  [x: string]: string;
};

const IconMapper: IconMapperType = {
  react: 'devicon-react-original text-[#61dafb]',
  redux: 'devicon-redux-original colored',
  next: 'devicon-nextjs-plain dark:text-white',
  // vue: 'devicon-vuejs-plain text-[#41b883]',
  node: 'devicon-nodejs-plain text-[#83cd29]',
  firebase: 'devicon-firebase-plain text-[#f58220]',
  mongodb: 'devicon-mongodb-plain text-[#4faa41]',
  tailwind: 'devicon-tailwindcss-plain text-[#2298bd]',
  bootstrap: 'devicon-bootstrap-plain colored',
  gcp: 'devicon-googlecloud-plain text-[#557ebf]',
  express: 'devicon-express-original dark:text-white',
  webpack: 'devicon-webpack-plain text-[#1c78c0]',
  typescript: 'devicon-typescript-plain text-[#007acc]',
  electron: 'devicon-electron-original colored',
  go: 'devicon-go-plain colored',
  docker: 'devicon-docker-plain colored',
  postgresql: 'devicon-postgresql-plain colored',
  supabase: 'devicon-supabase-plain colored',
  npm: 'devicon-npm-original-wordmark colored',
  vercel: 'devicon-vercel-original dark:text-white',
  mui: 'devicon-materialui-plain colored',
};

type SvgMapperType = {
  [x: string]: React.FC<React.SVGProps<SVGSVGElement>>;
};
const SVGMapper: SvgMapperType = {
  vue: Vue,
  chakra: Chakra,
  'google-maps': GoogleMaps,
  daisyui: Daisyui,
  web5: Web5,
  shadcn: Shadcn,
  radix: Radix,
};

const ProjectTechIcon = ({ name }: { name: string }) => {
  const iconClassName = IconMapper[name];

  if (!iconClassName) {
    const SVGComponent = SVGMapper[name];
    return (
      <div className="flex self-center justify-center w-6 h-6 mx-auto">
        <SVGComponent className="dark:text-white" />
      </div>
    );
  }

  return <i className={iconClassName} />;
};

export default ProjectTechIcon;
