import React from 'react';

import MERNEcommerce from '../../../images/projects/mern-ecommerce.svg';
import MEDCONNECT from '../../../images/projects/medconnect.svg';
import MOVEINZ from '../../../images/projects/moveinz.svg';
import GymBuddy from '../../../images/projects/gym-buddy-web.svg';
import ServicePro from '../../../images/projects/service-pro.svg';
import ChattyApp from '../../../images/projects/chatty-app.svg';
import NextCryptoSaaS from '../../../images/projects/next-crypto-saas.svg';
import NextSaaS from '../../../images/projects/next-saas.svg';
import NextNFTGallery from '../../../images/projects/next-nft-gallery.svg';
import VueCryptoSaaS from '../../../images/projects/vue-crypto-saas.svg';
import GoVueAuth from '../../../images/projects/go-vue-auth.svg';
import ReactSnackbarMessages from '../../../images/projects/react-snackbar-messages.svg';
import Web5ChatApp from '../../../images/projects/web5-chat-app.svg';

import { IProjectThumbnailSlug } from 'lib/types/app';

const ThumbnailMapper = {
  MERN_ECOMMERCE: MERNEcommerce,
  MEDCONNECT: MEDCONNECT,
  MOVEINZ: MOVEINZ,
  GYM_BUDDY_WEB: GymBuddy,
  SERVICE_PRO: ServicePro,
  CHATTY_APP: ChattyApp,
  NEXT_CRYPTO_SAAS: NextCryptoSaaS,
  NEXT_SAAS: NextSaaS,
  NEXT_NFT_GALLERY: NextNFTGallery,
  VUE_CRYPTO_SAAS: VueCryptoSaaS,
  GO_VUE_AUTH: GoVueAuth,
  REACT_SNACKBAR_MESSAGES: ReactSnackbarMessages,
  WEB5_CHAT_APP: Web5ChatApp,
  CHAT_COMPONENTS: Web5ChatApp,
};

const ProjectThumbnail = ({
  name,
  className,
}: {
  name: IProjectThumbnailSlug;
  className?: string;
}) => {
  const SVGComponent = ThumbnailMapper[name];
  return <SVGComponent className={className} />;
};

export default ProjectThumbnail;
